import {useQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {useRouter} from 'next/router'
import useDidMountEffect from '@hook/UseDidMountEffect'
import {apiGetTrending} from '@api/announce/getTrending'

export const PAGE_SIZE = 8
const useQueryGetTrending = (pageSize?: number, currentPage?: number, enabled?: boolean, id?: string) => {
    const router = useRouter()
    const {
        data: responseData,
        refetch,
        isLoading,
        isFetching,
        isSuccess,
    } = useQuery({
        queryKey: QueryCacheKeys.post.getTrending(currentPage, router?.locale),
        staleTime: 120 * 1000,
        enabled: enabled ?? true,
        cacheTime: 120 * 1000,
        queryFn: async ({pageParam}) => {
            const {data, pageInfo} = await apiGetTrending(
                String(pageParam).length >= 10 ? currentPage : pageParam ?? currentPage,
                pageSize ?? PAGE_SIZE,
                router?.locale,
            )
            return {data: data, pageInfo: pageInfo}
        },
    })

    useDidMountEffect(() => {
        refetch()
    }, [router?.locale])
    return {
        trend: responseData?.data,
        pageInfo: responseData?.pageInfo,
        refetch,
        isLoading,
        isFetching,
        isSuccess,
    }
}

export default useQueryGetTrending
