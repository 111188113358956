import {useMutation} from '@tanstack/react-query'
import {apiUnfollow} from '@api/user/profile/unfollow'
import {apiFollow} from '@api/user/profile/follow'

export const useMutationFollowUnfollow = () => {
    return useMutation({
        mutationFn: async ({isFollowing, user_id}: {isFollowing: boolean; user_id: string}) =>
            isFollowing ? await apiUnfollow(user_id) : await apiFollow(user_id),
    })
}
