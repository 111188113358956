import {useQuery} from '@tanstack/react-query'
import {apiGetReplies} from '@api/timeline/comment'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {TComment} from '@api/timeline/type'

export const useQueryGetReplies = (parentId?: string, enabled: boolean = false) => {
    return useQuery({
        enabled: enabled && parentId && parentId.length > 0,
        queryKey: QueryCacheKeys.timeline.getReplies(parentId),
        queryFn: async () => {
            const {data} = await apiGetReplies(parentId)
            if (!data) return [] as TComment[]
            return data
        },
    })
}
