import {FeedType, TFeedList, TimelineChannel, TPost} from '@api/timeline/type'
import {useInfiniteQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetFeeds} from '@api/timeline/timeline'
import {useMemo} from 'react'
import {useTimelineChannel} from '../../../../pages/timeline/features/store/useTimelineChannel'

const PAGE_SIZE = 20
export const useInfiniteQueryGetFeeds = (feedType: FeedType, enabled: boolean = true) => {
    const {channel} = useTimelineChannel()
    const {data, refetch, isLoading, isFetching, fetchNextPage} = useInfiniteQuery({
        enabled,
        queryKey: QueryCacheKeys.timeline.getFeeds(feedType, channel),
        queryFn: async ({pageParam = ''}) => {
            const {data} = await apiGetFeeds(feedType, channel === 'all' ? undefined : channel, pageParam)
            if (!data) return {} as TFeedList
            return data
        },
        getNextPageParam: lastPage => {
            if (lastPage?.next) {
                return lastPage.next
            }
        },
    })

    const feeds = useMemo(() => {
        return data?.pages?.reduce<TPost[]>((prev, curr) => {
            return prev.concat(curr.data ?? [])
        }, [])
    }, [data])

    const isEmpty = data?.pages?.at(0)?.data?.length === 0
    const isReachingEnd = isEmpty || (data && data?.pages?.at(-1)?.data?.length < PAGE_SIZE)

    return {feeds, refetch, isLoading, isFetching, isReachingEnd, fetchNextPage}
}
