import {useMemo} from 'react'
import {useInfiniteQuery} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {TPost} from '@api/timeline/type'
import {apiGetTimeline} from '@api/timeline/timeline'

const PAGE_SIZE = 50

export const useInfiniteQueryGetUserTimeline = (userId?: string, enable?: boolean) => {
    const {data, refetch, isLoading, isFetching, fetchNextPage, hasNextPage} = useInfiniteQuery({
        enabled: userId && userId.length > 0 && enable,
        queryKey: QueryCacheKeys.timeline.getUserFeeds(userId),
        queryFn: async ({pageParam}: {pageParam?: number}) => {
            const {data} = await apiGetTimeline(userId, pageParam)
            if (!data) return []
            return data
        },
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage && lastPage.length > 0) {
                const totalItems = allPages.reduce((sum, page) => sum + page.length, 0)
                return totalItems
            } else {
                return undefined
            }
        },
    })

    const feeds = useMemo(() => {
        return data?.pages?.reduce<TPost[]>((prev, curr) => {
            return prev.concat(curr)
        }, [])
    }, [data])

    const isEmpty = data?.pages?.at(0)?.length === 0
    const isReachingEnd = isEmpty || (data && data?.pages?.at(-1)?.length < PAGE_SIZE)

    return {feeds, refetch, isLoading, isFetching, fetchNextPage, isReachingEnd, hasNextPage}
}
