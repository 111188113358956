import {useQuery} from '@tanstack/react-query'
import useDidMountEffect from '@hook/UseDidMountEffect'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {apiGetCopierDashboard} from '@api/copy/copier/dashboard/getCopyDashboard'
import {isNotEmpty} from '@util/strings'

const useQueryGetUserCopyDashboard = (id: string, intervalTime?: number, enabled?: boolean) => {
    const router = useRouter()

    const {data, refetch, isLoading, isFetching, isSuccess} = useQuery({
        queryKey: QueryCacheKeys.copyfolio.getUserCopyDashboard(id),
        staleTime: 5000,
        refetchInterval: intervalTime ?? false,
        queryFn: async () => {
            const {data} = await apiGetCopierDashboard(id)
            return data
        },
        enabled: !!id ? true : enabled ? true : isNotEmpty(id),
        refetchOnWindowFocus: !intervalTime ?? false,
    })

    useDidMountEffect(() => {
        refetch()
    }, [router?.locale])

    return {data, refresh: refetch, isLoading, isFetching, isSuccess}
}

export default useQueryGetUserCopyDashboard
