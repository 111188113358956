import {useMutation} from '@tanstack/react-query'
import {apiBanUser} from '@api/ban/ban'
import {useQueryGetBanUserIdList} from '@hook/query/ban/useQueryGetBanUserIdList'

export const useMutationBanUser = () => {
    const {refetch} = useQueryGetBanUserIdList(false)
    return useMutation({
        mutationFn: async (ban_user_id: string) => await apiBanUser(ban_user_id),
        onSuccess: async ({data}) => {
            if (!data?.success) return
            await refetch()
        },
    })
}
