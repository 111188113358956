import {useQuery} from '@tanstack/react-query'
import {apiGetComments} from '@api/timeline/comment'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {TComment} from '@api/timeline/type'

export const useQueryGetComments = (postId?: string, enabled: boolean = false) => {
    return useQuery({
        enabled: enabled && postId && postId.length > 0,
        queryKey: QueryCacheKeys.timeline.getComments(postId),
        queryFn: async () => {
            const {data} = await apiGetComments(postId)
            if (!data) return [] as TComment[]
            return data
        },
    })
}
