import {useMutation, useQueryClient} from '@tanstack/react-query'
import {apiWritePost} from '@api/timeline/timeline'
import {Feed, WritePostParams} from '@api/timeline/type'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {useTimelineStore} from '../../../../pages/timeline/features/store/useTimelineStore'
import {useTimelineChannel} from '../../../../pages/timeline/features/store/useTimelineChannel'

export const useMutationCreatePost = () => {
    const queryClient = useQueryClient()
    const {channel} = useTimelineChannel()
    const {activeTab} = useTimelineStore(state => state)

    return useMutation({
        mutationFn: async (post: WritePostParams) => await apiWritePost(post),
        onSuccess: async ({data}) => {
            if (activeTab === 'my') {
                await queryClient.refetchQueries(QueryCacheKeys.timeline.getUserFeeds(data?.post?.user_id))
                return
            }
            await queryClient.refetchQueries(
                QueryCacheKeys.timeline.getFeeds(activeTab === 'all' ? Feed.All : Feed.Subscribed, channel),
            )
        },
    })
}
