import {useQuery} from '@tanstack/react-query'
import {useRouter} from 'next/router'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {getSubscribings} from '@api/user/account/subscriber/getSubscribings'
import {isNotEmpty} from '@util/strings'

const useQueryFollowing = (user_id: string, enabled: boolean = true) => {
    const router = useRouter()

    const {data, refetch, isLoading} = useQuery({
        queryKey: QueryCacheKeys.subscribers.getSubscribings(user_id, router?.locale),
        staleTime: 10 * 60 * 1000,
        cacheTime: 10 * 60 * 1000,
        queryFn: async () => {
            const {data} = await getSubscribings(user_id, router?.locale)
            return data
        },
        enabled: enabled && isNotEmpty(user_id),
    })

    return {data, refresh: refetch, isLoading}
}

export default useQueryFollowing
