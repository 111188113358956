import {TPost} from '@api/timeline/type'
import {useQuery} from '@tanstack/react-query'
import {apiGetPost} from '@api/timeline/timeline'
import {QueryCacheKeys} from '@hook/query/queryKeys'

export const useQueryGetPost = (id: string, enabled = false) => {
    return useQuery({
        enabled,
        staleTime: 60 * 1000,
        cacheTime: 60 * 1000,
        queryKey: QueryCacheKeys.timeline.getPost(id),
        queryFn: async () => {
            const {data} = await apiGetPost(id)
            if (!data) return {} as TPost
            return data
        },
    })
}
