import {useRouter} from 'next/router'
import {InfiniteData, useMutation, useQueryClient} from '@tanstack/react-query'
import {apiDislikePost, apiLikePost} from '@api/timeline/timeline'
import {useTimelineStore} from '../../../../pages/timeline/features/store/useTimelineStore'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {Feed, TFeedList, TPost} from '@api/timeline/type'
import {useTimelineListData} from '../../../../pages/timeline/features/hooks/useTimelineListData'
import {useTimelineChannel} from '../../../../pages/timeline/features/store/useTimelineChannel'

type TLikePostParams = {
    is_liked: boolean
    parent_id: string
    user_id?: string
}

export const useMutationLikePost = () => {
    const router = useRouter()
    const queryClient = useQueryClient()
    const {activeTab} = useTimelineStore()
    const {channel} = useTimelineChannel()

    const updateFeeds = ({is_liked, parent_id}: TLikePostParams) => {
        const key = QueryCacheKeys.timeline.getFeeds(activeTab === 'all' ? Feed.All : Feed.Subscribed, channel)
        queryClient.setQueriesData<InfiniteData<TFeedList>>(key, (oldData): InfiniteData<TFeedList> => {
            const newPage = oldData?.pages.map(page => {
                const newData = page?.data?.map(post => {
                    const newPost = {
                        ...post,
                        is_liked: !is_liked,
                        likes_count: is_liked
                            ? post?.likes_count > 0
                                ? post?.likes_count - 1
                                : 0
                            : post?.likes_count + 1,
                    }
                    return post?.id === parent_id ? newPost : post
                })

                return {data: newData, next: page?.next} as TFeedList
            })
            return {
                pages: newPage ?? oldData?.pages ?? [],
                pageParams: oldData?.pageParams ?? [],
            }
        })
    }

    const updateUserFeeds = ({is_liked, parent_id, user_id}: TLikePostParams) => {
        const key = QueryCacheKeys.timeline.getUserFeeds(user_id)
        queryClient.setQueriesData<InfiniteData<TPost[]>>(key, (oldData): InfiniteData<TPost[]> => {
            const newPage = oldData?.pages.map(data => {
                return data?.map(post => {
                    const newPost = {
                        ...post,
                        is_liked: !is_liked,
                        likes_count: is_liked
                            ? post?.likes_count > 0
                                ? post?.likes_count - 1
                                : 0
                            : post?.likes_count + 1,
                    }
                    return post?.id === parent_id ? newPost : post
                })
            })
            return {
                pages: newPage ?? oldData?.pages ?? [],
                pageParams: oldData?.pageParams ?? [],
            }
        })
    }

    return useMutation({
        mutationFn: async ({is_liked, parent_id}: TLikePostParams) =>
            is_liked ? await apiDislikePost(parent_id) : await apiLikePost(parent_id),
        onSuccess: async ({data}, {is_liked, parent_id, user_id}: TLikePostParams) => {
            if (!data?.success) return
            if (router?.asPath?.includes(parent_id)) return // detail
            if (activeTab === 'my' || router?.asPath?.includes(user_id)) {
                // user timeline list, my timeline list
                updateUserFeeds({is_liked, parent_id, user_id})
                return
            }
            updateFeeds({is_liked, parent_id})
        },
    })
}
