import {useQuery} from '@tanstack/react-query'
import {apiGetBanUserIdList} from '@api/ban/ban'
import {QueryCacheKeys} from '@hook/query/queryKeys'

export const useQueryGetBanUserIdList = (enabled: boolean = true) => {
    return useQuery({
        enabled,
        staleTime: 60 * 60 * 1000,
        cacheTime: 60 * 60 * 1000,
        queryKey: QueryCacheKeys.ban.getBanUserIdList(),
        queryFn: async () => {
            const {data} = await apiGetBanUserIdList()
            if (data) return data
            return [] as string[]
        },
    })
}
