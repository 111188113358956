import {InfiniteData, useMutation, useQueryClient} from '@tanstack/react-query'
import {apiDeletePost} from '@api/timeline/timeline'
import {useTimelineChannel} from '@pages/timeline/features/store/useTimelineChannel'
import {useTimelineStore} from '@pages/timeline/features/store/useTimelineStore'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {Feed, TFeedList, TPost} from '@api/timeline/type'

export const useMutationDeletePost = () => {
    const queryClient = useQueryClient()
    const {channel} = useTimelineChannel()
    const {activeTab} = useTimelineStore(state => state)

    const deletePost = (post_id: string) => {
        const key = QueryCacheKeys.timeline.getFeeds(activeTab === 'all' ? Feed.All : Feed.Subscribed, channel)
        queryClient.setQueriesData<InfiniteData<TFeedList>>(key, (oldData): InfiniteData<TFeedList> => {
            const newPage = oldData?.pages.map(page => {
                const newData = page?.data?.filter(post => post?.id !== post_id)
                return {
                    data: newData,
                    next: page?.next,
                } as TFeedList
            })
            return {
                pages: newPage ?? oldData?.pages ?? [],
                pageParams: oldData?.pageParams ?? [],
            }
        })
    }
    const deleteUserPost = (post_id: string, user_id: string) => {
        const key = QueryCacheKeys.timeline.getUserFeeds(user_id)
        queryClient.setQueriesData<InfiniteData<TPost[]>>(key, (oldData): InfiniteData<TPost[]> => {
            const newPage = oldData?.pages.map(page => {
                return page?.filter(post => post?.id !== post_id)
            })
            return {
                pages: newPage ?? oldData?.pages ?? [],
                pageParams: oldData?.pageParams ?? [],
            }
        })
    }

    return useMutation({
        mutationFn: async ({post_id}: {post_id: string; user_id: string}) => await apiDeletePost(post_id),
        onSuccess: async (_, {post_id, user_id}) => {
            deletePost(post_id)
            deleteUserPost(post_id, user_id)
        },
    })
}
